import { Carousel } from '@mantine/carousel';
import { Container, Divider, Flex, Grid, GridCol, Group, Image, Kbd, List, ListItem, Paper, Space, Text, Title } from '@mantine/core';
import DownloadCard from '../components/downloadcard';
import rootImage from '../assets/3.png';
import subsImage from '../assets/5.png';
import formatsImage from '../assets/4.png';
import Stache from '../svgs/Stache';
import { FaArrowDown } from 'react-icons/fa';
import DownloadButton from '../components/downloadbutton';

const HomePage = () => {
    return (
        <Grid>
            <GridCol span={12}>
                <Carousel
                    height="80vh"
                    loop
                    align="center"
                    slideSize="100%"
                    slideGap="md"
                    withIndicators={true}
                    withControls={true}
                >
                    <Carousel.Slide>
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            style={{ height: '100%' }}
                            gap="xl"
                        >
                            <Stache
                                bg="transparent"
                                fg="white"
                                width="300px"
                            />
                            <Title order={1} ta="center">Stacher7</Title>
                            <Text ta="center" c="dimmed">
                                A modern GUI for yt-dlp (and other youtube-dl forks)
                            </Text>
                            <DownloadButton />
                            <Divider />
                            <Space h="xl" />
                            <Text ta="center" c="dimmed" size="xs">
                                drag this slide to the left or use the controls to slide through screenshots
                            </Text>
                        </Flex>
                    </Carousel.Slide>
                    <Carousel.Slide
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            fontSize: 40
                        }}
                    >
                        <Flex
                            direction="row"
                            align="center"
                            justify="center"
                            style={{ height: '100%' }}
                        >
                            <Image
                                src={rootImage}
                                w="100vw"
                                h="calc(80vh - 40px)"
                            />
                            <Paper
                                p="xl"
                                withBorder
                                visibleFrom='md'
                            >
                                <Text
                                    size="xl"
                                >
                                    Features:
                                </Text>
                                <List>
                                    <ListItem>Modern GUI</ListItem>
                                    <ListItem>Manage multiple yt-dlp configurations</ListItem>
                                    <ListItem>Automate yt-dlp with Subscriptions</ListItem>
                                    <ListItem>Video Trimming</ListItem>
                                    <ListItem>Access To Most of yt-dlp functionality via GUI</ListItem>
                                    <ListItem>yt-dlp plugin support (comming soon!)</ListItem>
                                    <ListItem>Light and Dark Mode</ListItem>
                                    <ListItem>Automated FFMPEG Installation Tool</ListItem>
                                    <ListItem>Automatically keep yt-dlp updated</ListItem>
                                    <ListItem>Quickly change settings with <Kbd>CTRL</Kbd>+<Kbd>P</Kbd> spotlight</ListItem>
                                    <ListItem>Hotkey shortcuts and "Pro Mode" for advanced users</ListItem>
                                    <ListItem>yt-dlp log viewer</ListItem>
                                    <ListItem>A growing reddit community at <a href="https://reddit.com/r/stacherio" target="_blank">/r/stacherio</a></ListItem>
                                    <ListItem>No ads, ever. period.</ListItem>
                                    <ListItem>And a lot more!</ListItem>
                                </List>
                            </Paper>
                        </Flex>
                    </Carousel.Slide>
                    <Carousel.Slide
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            fontSize: 40
                        }}
                    >
                        <Image src={formatsImage} w="100vw" h="calc(80vh - 40px)" />
                    </Carousel.Slide>
                    <Carousel.Slide
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            fontSize: 40
                        }}
                    >
                        <Image src={subsImage} w="100vw" h="calc(80vh - 40px)" />
                    </Carousel.Slide>
                </Carousel>
            </GridCol>
            <GridCol span={12}>
                <Space h="xl" />
                <Group
                    align="center"
                    justify="center"
                    style={{
                        gap: "20px",
                        color: "white"
                    }}
                >
                    <Title order={1} ta="center">All available downloads</Title><FaArrowDown color="white" size="20px" />
                </Group>
                <Space h="xl" />
            </GridCol>
            <GridCol span={{
                xs: 12,
                sm: 12,
                md: 3,
                lg: 3,
                xl: 3
            }}
            >
                <DownloadCard type="windows" />
            </GridCol>
            <GridCol span={{
                xs: 12,
                sm: 12,
                md: 3,
                lg: 3,
                xl: 3
            }}
            >
                <DownloadCard type="macintel" />
            </GridCol>
            <GridCol span={{
                xs: 12,
                sm: 12,
                md: 3,
                lg: 3,
                xl: 3
            }}
            >
                <DownloadCard type="macsilicon" />
            </GridCol>
            <GridCol span={{
                xs: 12,
                sm: 12,
                md: 3,
                lg: 3,
                xl: 3
            }}
            >
                <DownloadCard type="linux" />
            </GridCol>
            <GridCol span={12}>
                <Space h="100px" />
                <Container
                    h="40vh"
                >
                    <Text ta="center" c="dimmed" size="sm">
                        Stacher is a hobby project. Although fully dependent on yt-dlp, Stacher is not affiliated with yt-dlp or youtube-dl in any way. 
                    </Text>
                    <Text ta="center" c="dimmed" size="sm">
                        Please check yt-dlp documentation for more information on yt-dlp: <a href="https://github.com/yt-dlp/yt-dlp/" target="_blank">https://github.com/yt-dlp/yt-dlp/</a>
                    </Text>
                    <Space h="md" />
                    <Text ta="center" c="dimmed" size="sm">
                        <a href="https://github.com/yt-dlp/FFmpeg-Builds#ffmpeg-static-auto-builds" target="_blank">yt-dlp custom ffmpeg builds</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp; 
                        <a href="https://github.com/yt-dlp/yt-dlp/?tab=readme-ov-file#installation">yt-dlp latest binaries</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp; 
                        <a href="https://reddit.com/r/stacherio" target="_blank">/r/stacherio</a>
                        &nbsp;&nbsp;|&nbsp;&nbsp; 
                        <a href="https://reddit.com/r/youtubedl" target="_blank">/r/youtubedl</a>
                    </Text>
                    <Space h="xl" />
                    <Text ta="center" c="dimmed" size="sm">
                        Thank you all for your support!
                    </Text>
                </Container>
            </GridCol>
        </Grid>
    )
}

export default HomePage;