import { createTheme, rem } from "@mantine/core";
import { createMantineTheme } from "./createMantineTheme";

export const theme = createMantineTheme({
    baseHue: 255,
    baseSaturation: 2,
    colors: {
      primary: [
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E",
        "#FF306E"
      ],
      secondary: [
        "#fff0e4",
        "#ffe0cf",
        "#fac0a1",
        "#f69e6e",
        "#f28043",
        "#f06e27",
        "#f06418",
        "#d6530c",
        "#bf4906",
        "#a73c00"
      ],
      tertiary: [
        "#f7f3f2",
        "#e8e6e5",
        "#d2c9c6",
        "#bdaaa4",
        "#ab9087",
        "#a17f74",
        "#9d766a",
        "#896459",
        "#7b594e",
        "#6d4b40"
      ],
    },
    shadows: {
      md: '1px 1px 3px rgba(0, 0, 0, .25)',
      xl: '5px 5px 3px rgba(0, 0, 0, .25)',
    },
  
    headings: {
      fontFamily: 'Roboto, sans-serif',
      sizes: {
        h1: { fontSize: rem(36) },
      },
    },

  });


export const themeOld = createTheme({
    primaryColor: 'pink',
    colors: {
      light: [
        "#eaeaea", // Darker than #fbf3f5
        "#dcdcdc", // Darker than #e7e7e7
        "#c2c2c2", // Darker than #cdcdcd
        "#a8a8a8", // Darker than #b2b2b2
        "#909090", // Darker than #9a9a9a
        "#828282", // Darker than #8b8b8b
        "#7a7a7a", // Darker than #848484
        "#676767", // Darker than #717171
        "#5d5d5d", // Darker than #656565
        "#545454"  // Darker than #5c5557
      ],
      pink: [
        '#ffacc5', // 0 - light
        '#ff98b7', // 1
        '#ff83a8', // 2
        '#ff6e9a', // 3
        '#ff598b', // 4
        '#ff457d', // 5
        '#FF306E', // 6 - primary color
        '#e62b63', // 7
        '#cc2658', // 8
        '#b3224d', // 9 - dark
      ],
    },
  
    shadows: {
      md: '1px 1px 3px rgba(0, 0, 0, .25)',
      xl: '5px 5px 3px rgba(0, 0, 0, .25)',
    },
  
    headings: {
      fontFamily: 'Roboto, sans-serif',
      sizes: {
        h1: { fontSize: rem(36) },
      },
    },

  });