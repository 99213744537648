import { AppShell, Flex, Text, Title } from "@mantine/core";
import HomePage from "./pages/homepage";
import { FaHeart } from "react-icons/fa";

function App() {
  return (
    <AppShell
      header={{height: 60}}
      padding="md"
    >
      <AppShell.Header
        style={{ marginBottom: 20 }}
      >
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "100%" }}
          pl="1rem"
          pr="1rem"
        >
          <Title order={2} style={{ marginRight: 10 }}>S7</Title>
          <Text size="xs" c="dimmed">
            <FaHeart style={{ marginRight: 5 }} />
          </Text>
        </Flex>
      </AppShell.Header>
      <AppShell.Main>
        <HomePage />
      </AppShell.Main>
    </AppShell>
  );
}

export default App;
