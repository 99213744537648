import { Button, Group } from "@mantine/core";
import { download_links } from "../util";


const DownloadButton = () => {


    const getOs = () => {
        const userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (macosPlatforms.indexOf(platform) !== -1) {
            return 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            return 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            return 'Windows';
        } else if (/Android/.test(userAgent)) {
            return 'Android';
        } else if (/Linux/.test(platform)) {
            return 'Linux';
        }
        return 'Unknown OS';
    }

    if (getOs() !== 'Windows' && getOs() !== 'Mac OS' && getOs() !== 'Linux') {
        return <></>;
    }

    if (getOs() === "Mac OS") {
        return (
            <Group
            >
                <Button
                    variant="outline"
                    pt={"2px"}
                    onClick={() => {
                        window.open(download_links.macintel, "_blank")
                    }}
                >
                    Download For {getOs()} (Intel)
                </Button>
                <Button
                    variant="outline"
                    pt={"2px"}
                    onClick={() => {
                        window.open(download_links.macsilicon, "_blank")
                    }}
                >
                    Download For {getOs()} (Apple Silicon)
                </Button>
            </Group>
        )
    }

    return (
        <Button
            variant="outline"
            pt={"2px"}
            onClick={() => {
                // @ts-ignore
                window.open(download_links[getOs().toLowerCase()], "_blank")
            }}
        >
            Download For {getOs()}
        </Button>
    )

}

export default DownloadButton;